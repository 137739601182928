@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lato', sans-serif;   
}

.hide-scrollbar::-webkit-scrollbar {
    display: none,
}

/* Change the background color to black when scrolling outside the document */
body::-webkit-scrollbar-corner, html {
    background-color: #151515;
}

.writing-mode-vertical {
    writing-mode: vertical-rl; /* Sets the writing mode to vertical, from right to left */
    text-orientation: upright; /* Sets the orientation of the text to upright */
    white-space: nowrap; /* Prevents the text from wrapping onto multiple lines */
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}