@keyframes pulse {
    /* 0%, 100% {
      transform: scale(0.5);
      opacity: 1;
    } */
    50% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes rotate-right {
    0%, 100% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(15deg);
    }
  }

  @keyframes rotate-left {
    0%, 100% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-15deg);
    }
  }

  @keyframes jump {
    0%   {transform: translate3d(0,0%,0) scale3d(1,1,1);}
    40%  {transform: translate3d(0,-30%,0) scale3d(.9,1.2,1);}
    100% {transform: translate3d(0,-45%,0) scale3d(1.2,.9,1);}
  }

@keyframes pop-in {
    0% { opacity: 0; transform: scale(0.6) translateX(-50%); }
    50% { transform: translateX(0px); }
    100% { opacity: 1; transform: scale(1) }
}
  
@keyframes pop-in-from-left {
    0% { opacity: 0; transform: scale(0.6) translateX(-40%); }
    50% { transform: translateX(0px); }
    100% { opacity: 1; transform: scale(1) }
}

@keyframes pop-in-from-right {
    0% { opacity: 0; transform: scale(0.6) translateX(40%); }
    50% { transform: translateX(0px); }
    100% { opacity: 1; transform: scale(1) }
}

@keyframes pop-in-from-top {
    0% { opacity: 0; transform: scale(0.8) translateY(-200%); }
    50% { transform: translateY(0px); }
    100% { opacity: 1; transform: scale(1) }
}
  
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fade-in-blur {
  from {
      opacity: 0;
      filter: blur(8px);
  }
  to {
      opacity: 1;
      filter: blur(0px);
  }
}

@keyframes fade-out-blur {
  from {
      opacity: 1;
      filter: blur(0);
  }
  to {
      opacity: 0;
      filter: blur(6px);
  }
}

@keyframes fade-in-from-top {
    from {
      opacity: 0;
      transform: scale(0.1);
    }
    to {
      opacity: 1;
      transform: scale(1.0);
    }
  }

.animate-pulse {
    animation: pulse 3s ease-in-out infinite alternate-reverse;
  }

.animate-wiggle-right {
    animation: rotate-right 1.5s ease-in-out alternate-reverse;
    transform-origin: center bottom; 
  }

.animate-wiggle-left {
    animation: rotate-left 1.5s ease-in-out alternate-reverse;
    transform-origin: center bottom; 
  }

.animate-jump {
    animation: jump 0.5s linear alternate;
    animation-iteration-count: 2;
    transform-origin: 50% 50%;
  }

.animate-pop-in {
    animation:pop-in 1.5s ease-in-out forwards;
}

.animate-pop-in-from-top {
    animation:pop-in-from-top 1.5s ease-in-out forwards;
}

.animate-pop-in-from-right {
    animation:pop-in-from-right 1.5s ease-in-out forwards;
}

.animate-pop-in-from-left {
    animation:pop-in-from-left 1.5s ease-in-out forwards;
}

.animate-fade-in {
    animation:fade-in 1.0s ease-in-out forwards;
}

.animate-fade-out {
    animation:fade-out 1.0s ease-in-out forwards;
}

.animate-fade-out-blur {
    animation:fade-out-blur 1.0s ease-in-out forwards;
}

.animate-fade-in-blur {
    animation:fade-in-blur 1.0s ease-in-out forwards;
}

.delay-300 { animation-delay: 300ms; }
.delay-400 { animation-delay: 400ms; }
.delay-450 { animation-delay: 450ms; }
.delay-500 { animation-delay: 500ms; }
.delay-600 { animation-delay: 600ms; }
.delay-700 { animation-delay: 700ms; }
.delay-750 { animation-delay: 750ms; }
.delay-800 { animation-delay: 800ms; }
.delay-900 { animation-delay: 900ms; }
.delay-1000 { animation-delay: 1000ms; }
.delay-1100 { animation-delay: 1100ms; }
.delay-1200 { animation-delay: 1200ms; }
.delay-1300 { animation-delay: 1300ms; }
.delay-1400 { animation-delay: 1400ms; }
.delay-1500 { animation-delay: 1500ms; }
.delay-1600 { animation-delay: 1600ms; }
.delay-1650 { animation-delay: 1650ms; }
.delay-1750 { animation-delay: 1750ms; }
.delay-1800 { animation-delay: 1800ms; }
.delay-1850 { animation-delay: 1850ms; }
.delay-1900 { animation-delay: 1900ms; }
.delay-2000 { animation-delay: 2000ms; }
.delay-2100 { animation-delay: 2100ms; }
.delay-2200 { animation-delay: 2200ms; }
.delay-2300 { animation-delay: 2300ms; }
.delay-2400 { animation-delay: 2400ms; }
.delay-2500 { animation-delay: 2500ms; }
.delay-2600 { animation-delay: 2600ms; }
.delay-2700 { animation-delay: 2700ms; }
.delay-2800 { animation-delay: 2800ms; }
.delay-2900 { animation-delay: 2900ms; }
.delay-3000 { animation-delay: 3000ms; }
.delay-3100 { animation-delay: 3100ms; }
.delay-3200 { animation-delay: 3200ms; }
.delay-3300 { animation-delay: 3300ms; }
.delay-3400 { animation-delay: 3400ms; }
.delay-3500 { animation-delay: 3500ms; }
.delay-3600 { animation-delay: 3600ms; }
.delay-3750 { animation-delay: 3750ms; }
.delay-3800 { animation-delay: 3800ms; }
.delay-3900 { animation-delay: 3900ms; }
.delay-4000 { animation-delay: 4000ms; }
.delay-4500 { animation-delay: 4500ms; }
.delay-5000 { animation-delay: 5000ms; }

.blogContent p {
  margin-bottom: 1em;
  font-family: 'Gilroy', sans-serif;
}

.blogContent a {
  font-family: 'Gilroy', sans-serif;
  text-decoration: underline;
  color: #FF8BAE,
}

.blogContent li {
  list-style-type: circle;
  color: #FF8BAE,
}

.blogContent ul {
  list-style-type: circle;
  color: #FF8BAE,
}

.blogContent h4 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-weight: bold;
  font-family: 'Gilroy', sans-serif;
}

.blogContent h3 {
  margin-top: 2em;
  font-weight: bold;
  font-family: 'Gilroy', sans-serif;
}

.blogContent h2 {
  margin-top: 2em;
  font-weight: bold;
  font-family: 'Gilroy', sans-serif;
}

.blogContent h1 {
  margin-top: 2em;
  font-weight: bold;
  font-family: 'Gilroy', sans-serif;
}